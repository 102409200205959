import React, { useState } from "react"
import Axios from "axios"
import { Form, Button } from "react-bootstrap"
import { navigate } from "gatsby"

import Alert from "../alert"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faEnvelope, faLock, faUser } from "@fortawesome/free-solid-svg-icons"

const RegisterSection = ({ onData, endpoint, validate, redirectURL }) => {
  // registration
  const [state, setState] = useState({
    submitting: false,
    status: null,
  })

  const [isLoading, setIsLoading] = useState(false)
  const handleServerResponse = (ok, msg, form) => {
    setState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    const formElement = e.target
    const formData = new FormData(formElement)

    let data = {}
    for (let [k, v] of formData.entries()) {
      data[k] = v
    }
    setIsLoading(true);

    if (validate && validate instanceof Function) {
      data = validate(data)
      const { status, message, data: dataMassaged } = data

      if (status === false) {
        handleServerResponse(false, message, formElement)
      } else {
        data = dataMassaged
      }
    }

    setState({ submitting: true })
    Axios({
      method: "post",
      url: endpoint,
      data,
    })
      .then(r => {
        handleServerResponse(true, "Registered!", formElement)
        if (typeof window !== "undefined") {
          setIsLoading(false);
          navigate(redirectURL || "/login", {
            state: {
              msg: [
                {
                  type: "info",
                  title: "Successfully registered!",
                  body:
                    "An email is sent to your email for verification, please verify your email",
                },
              ],
            },
          })
        }
      })
      .catch(({ response }) => {
        setIsLoading(false);
        handleServerResponse(
          false,
          ((response || {}).data || {}).errors || "Login Error",
          formElement,
        )
      })
  }

  const getMessage = message => {
    if (typeof message === "string") {
      return <span>{message}</span>
    }
    if (typeof message === "object") {
      return (
        <ul>
          {Object.keys(message).map(m => (
            <li>
              <strong>{m}</strong>: <span>{message[m].map(t => t)}</span>
            </li>
          ))}
        </ul>
      )
    }

    return undefined
  }

  return (
    <>
      <h2 className="mb-0 mb-md-0 text-blue"><strong>Registration Form</strong><br/><br/></h2>
      <Form onSubmit={handleOnSubmit} enctype="multipart/form-data" className="mr-lg-5 m-0 p-0">
        {((state || {}).status || {}).ok === false ? (
          <Alert
            type="danger"
            title="Login Error"
            message={getMessage(((state || {}).status || {}).msg)}
          />
        ) : null}
        <div className="text-white bg-blue round p-3 pt-4 pr-5 mt-5 boder-white box-shadow2 position-relative">

          <div className="bg-blue boder-white rounded-circle user-icon">
            <FontAwesomeIcon icon={faUser} className="far"/>
          </div>
          <fieldset disabled={state.submitting} className="p-3">
            <label htmlFor="exampleInputEmail1">USER NAME</label>
            <Form.Group className="mb-2 input-group">
              <div className="input-group-prepend">
                <FontAwesomeIcon icon={faUser} className="far"/>
              </div>
              <Form.Control
                name="name"
                type="text"
                className="form-control round"
                required
              />
            </Form.Group>
            <label htmlFor="exampleInputEmail1">E-MAIL</label>
            <Form.Group className="mb-2 input-group">
              <div className="input-group-prepend">
                <FontAwesomeIcon icon={faEnvelope} className="fas"/>
              </div>
              <Form.Control
                name="email"
                type="email"
                required
                className="form-control round"
              />
            </Form.Group>
            <label htmlFor="exampleInputPassword1">PASSWORD</label>
            <Form.Group className="mb-2 input-group">
              <div className="input-group-prepend">
                <FontAwesomeIcon icon={faLock} className="fas"/>
              </div>
              <Form.Control
                name="password"
                type="password"
                required
                className="form-control round"
              />
            </Form.Group>
            <label htmlFor="exampleInputPassword1">CONFIRM PASSWORD</label>
            <Form.Group className="mb-2 input-group">
              <div className="input-group-prepend">
                <FontAwesomeIcon icon={faLock} className="fas"/>
              </div>
              <Form.Control
                name="password_confirmation"
                type="password"
                required
                className="form-control round"
              />
            </Form.Group>
          </fieldset>
        </div>
        <div className="text-center my-4">
          <Button variant="light" className="btn btn-sm mx-2 border-0 text-black-50" disabled={isLoading} onClick={() => {
            if (typeof window !== "undefined") {
              navigate("/login", {
                redirectedFrom: "/register",
              })
            }
          }}>Cancel</Button>
          <Button type="submit" className="btn btn-primary btn-sm  mx-2" disabled={isLoading} >
            Save <FontAwesomeIcon icon={faArrowRight} className="fas fa-arrow-right"/>
          </Button>
        </div>
      </Form>
    </>
  )
}

export default RegisterSection
