import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../components/seo"
import RegisterSection from "../components/partials/register"

const Regsiter = props => {
  const {
    site: {
      siteMetadata: { apiURL },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            apiURL
          }
        }
      }
    `,
  )

  return (
    <>
      <div className="row mt-4 justify-content-center reg-form" id="registration-form">
        <div className="col-10 col-sm-10 col-lg-5 mt-3 mt-md-5">
            <RegisterSection
              endpoint={
                process.env.GATSBY_API_REGISTER_ENDPOINT ||
                `${apiURL}/register` ||
                undefined
              }
              onData={data => {
                return data
              }}
            />
          </div>
      </div>
      <SEO title="Register" keywords={[`mazrui`, `careers`]}/>
    </>
  )
}

export default Regsiter
